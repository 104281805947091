.footer-page{
    height: 10vh;
    width: 100%;
    text-align: center;
    color: white;
    border-top: 0.05vh solid gray;
    
}
.footer-text{display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 1vw;
    font-style: italic;
}

/* Media Query */
@media only screen and (max-width: 479.99px) {
    .footer-text{
        font-size: 3vw;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767.99px) {
    .footer-text {
            font-size: 3vw;
        }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .footer-text {
            font-size: 2vw;
        }
}