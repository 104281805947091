.text-senju {
    color: #20c997;
}

.text-namikaze {
    color: #ffba00;
}

.bg-g-senju{
    background-image: linear-gradient(to right,#20c997,#1aa078);
}
.bg-g-namikaze{
    background-image: linear-gradient(to right,#ffba00,#c18d00);
}
.bg-g-senju-namikaze{
    background-image: linear-gradient(to right,#20c997,#ffba00);
}
.bg-senju {
    background-color: #20c997;
}
.bg-namikaze {
    background-color: #ffba00;
}
.bg-meynikara{
    background-color: #1b1a1a;
}

/* For slider 3D */

.slide {
    transform: scale(0.8);
    transition: transform 300ms;
    opacity: 0.5;
}

.activeSlide {
    transform: scale(1.1);
    opacity: 1;
}
.next {
    position: absolute;
    right: 0%;
    top: 0;
}

.prev {
    position: absolute;
    left: 0%;
    top: 0%;
    z-index: 1;
}

/* For card */
.shadow-card{
    transition: all ease-in-out 0.15s;
}
.shadow-card:hover {
    background-color: #232323;
    scale: 1;
    transition: all ease-in-out 0.15s;
    border-bottom: 1vh solid #ffba00;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.shadow-card{
    background-color: #232323;
    scale: 0.95;
    transition: all ease-in-out 0.15s;
    border-bottom: 1vh solid #ffba00;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}



