.contact-page {
    padding: 5vh 5vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.contact-page-main {
    text-align: center;
    font-size: 2.5vw;
    font-weight: 700;
    color: white;
    background: linear-gradient(white, #333);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0vh 0 5vh 0;
}

.con-1 {
    margin: 5vh 0;
}
.col-8{
    padding-left: 5vw;
}

.helpful-heading {
    font-size: 1.5vw;
    font-weight: 500;
    color: white;

}

.helpful-links {
    margin: 2vh 0;
    justify-content: space-between;
}

.helpful-navlink {
    transition: all ease-in-out 0.2s;
    color: white;
    opacity: 0.75;
    font-size: 1vw;
    font-weight: 100;
    border-bottom: 0.1vh solid transparent;

    &:hover {
        opacity: 1;
        scale: 1.1;
        color: white;
        border-bottom: 0.1vh solid aquamarine;
    }
}

.social {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5vw;
}

.social-icon-container {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

}

.social-icon {
    color: white;
    font-size: 2vw;
}

.contact-page-arrow {
    rotate: -45deg;
}

.contact-us-address-heading {
    font-size: 1.5vw;
    font-weight: 500;
    margin-bottom: 3vh;
}

.contact-us-address-para {
    font-size: 1vw;
    font-weight: 300;
    margin-bottom: 3vh;
}
.contact-us-address {
    padding: 0 5vw;
}
.contact-page-hiddden-border{
    justify-content: center;
}
.contact-page-hiddden-border{
    height: 100%;
    width: 5%;
    background-image: linear-gradient(to bottom,aquamarine,transparent);
}

/* Media Query */
@media only screen and (max-width: 479.99px) {
    .helpful-navlink {
        font-size: 3vw;
    }

    .contact-page-main {
        font-size: 5vw;
        font-weight: 700;
    }

    .helpful-links {
        display: flex;
        flex-direction: column;
        justify-content: start;
    }

    .helpful-heading {
        font-size: 4vw;
    }

    .social-icon-container {
        padding: 2.5vh 0;
        align-items: start;
    }

    .social {
        padding: 0 3vw;
    }

    .con-1 {
        padding: 0 10vw;
    }

    .social-icon {
        font-size: 4vw;
    }
        .contact-us-address-heading {
            font-size: 4vw;
            font-weight: 500;
            margin-bottom: 3vh;
        }
    
        .contact-us-address-para {
            font-size: 3vw;
            font-weight: 100;
            margin-bottom: 3vh;
        }
    
        .contact-us-address {
            padding: 0 10vw;
        }
}

@media only screen and (min-width: 480px) and (max-width: 767.99px) {
    .helpful-navlink {
        font-size: 2vw;
    }

    .contact-page-main {
        font-size: 5vw;
        font-weight: 700;

    }

    .social-icon-container {
        padding: 2.5vh;
        width: 25vw;
    }

    .social {
        padding: 0 2vw;
    }

    .con-1 {
        padding: 0 10vw;
    }

    .social-icon {
        font-size: 4vw;
    }
        .contact-us-address-heading {
            font-size: 3vw;
            font-weight: 500;
            margin-bottom: 3vh;
        }
    
        .contact-us-address-para {
            font-size: 2.5vw;
            font-weight: 300;
            margin-bottom: 3vh;
        }
    
        .contact-us-address {
            padding: 0 10vw;
        }


}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .helpful-navlink {
        font-size: 2.5vw;
    }

    .contact-page-main {
        font-size: 4vw;
        font-weight: 700;

    }

    .helpful-heading {
        font-size: 3vw;
    }

    .social {
        padding: 0 2vw;
    }

    .helpful-links {
        display: flex;
        flex-direction: column;
        justify-content: start;
    }

    .social-icon-container {
        padding: 2.5vh 0;
        width: 25vw;
        align-items: start;
    }

    .con-1 {
        padding: 0 10vw;
    }

    .social-icon {
        font-size: 4vw;
    }

    .contact-us-address-heading {
        font-size: 3vw;
        font-weight: 500;
        margin-bottom: 3vh;
    }

    .contact-us-address-para {
        font-size: 2.5vw;
        font-weight: 300;
        margin-bottom: 3vh;
    }
    .contact-us-address{
        padding: 0 10vw;
    }
}