.GLIMPage5 {
    min-height: 50vh;
    padding: 0vh 5vw;
}

.g5-h {
    font-size: 2vw;
    font-weight: 500;
    width: fit-content;
    padding: 1vh 5vw;
    color: white;
    border-top-left-radius: 5vh;
    border-bottom-right-radius: 5vh;

}
.g5-p{
    text-align: justify;
}

.gmc-1,
.gmc-2 {
    border-radius: 5vh;
    background-color: #f1f1f1;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.g5-p {
    font-size: 1.25vw;
    font-weight: 400;
    padding: 2vh 3vw;
    padding-bottom: 5vh;
}

.g5-c {
    height: 3vh;
}

.video-frame {
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.gp5-video{
    height: 40vh;
    width: 50%;
    border-radius: 5vh;
    background-image: url("./video.png");
    background-size:cover ;
    background-position: center;
}



/* Media Query */
@media only screen and (max-width: 479.99px) {
    .g5-p {
        font-size: 3.5vw;
        padding-bottom: 3vh;
    }

    .g5-h {
        font-size: 5vw;
        padding-left: 10vw;
        padding-right: 10vw;
        border-top-left-radius: 3vh;
        border-bottom-right-radius: 3vh;
        margin: 0;
    }

    .gmc-1,
    .gmc-2 {
        border-radius: 3vh;
    }
        .video-frame {
            margin: 2.5vh 0;
            height: 25vh;
        }
    
        .gp5-video {
            height: 23vh;
            width: 100%;
            border-radius: 3vh;
        }

}

@media only screen and (min-width: 480px) and (max-width: 767.99px) {
    .g5-p {
        font-size: 2vw;
    }

    .g5-h {
        font-size: 3vw;
        border-top-left-radius: 3vh;
        border-bottom-right-radius: 3vh;
    }

    .gmc-1,
    .gmc-2 {
        border-radius: 3vh;
    }
        .video-frame {
            margin: 2.5vh 0;
            height: 30vh;
        }
    
        .gp5-video {
            height: 30vh;
            width: 100%;
            border-radius: 3vh;
        }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .g5-p {
        font-size: 2.5vw;
    }

    .g5-h {
        font-size: 3vw;
        border-top-left-radius: 3vh;
        border-bottom-right-radius: 3vh;
        margin: 0;
    }

    .gmc-1,
    .gmc-2 {
        border-radius: 3vh;
    }
        .video-frame {
            margin: 2.5vh 0;
            height: 30vh;
        }
    
        .gp5-video {
            height: 30vh;
            width: 100%;
            border-radius: 3vh;
        }
    
}