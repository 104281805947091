.GLIMPage-4 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.gp-4 {
    width: 100%;
    border-radius: 5vh;
    background-image: url("./image.png");
}

.gl-col-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5vh 5vw;
}

.glh-4 {
    font-size: 3vw;
    font-weight: 700;
}

.glp-4 {
    font-size: 1.5vw;
    font-weight: 400;
}

.gll-4 {
    display: flex;
    width: 50%;
}

.gl1,
.gl2 {
    height: 0.5vh;
    width: 25vw;
}

.gl-col-2 {
    align-items: center;
    justify-content: space-evenly;
}

.gl-col-2-card {
    height: 20vh;
    width: 20vw;
    background-color: white;
    border-radius: 2.5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.75vw;
    padding: 0.5vh 1vw;
    margin: 1vh 0;
}

.col-2-bold {
    font-weight: 700;
}

.col-2-icon {
    height: 5vh;
    width: 5vh;
}

.col-2-para {
    font-size: 1.5vw;
}

.col-lg-1 {
    display: flex;
    justify-content: center;
}

.gp-4 {
    padding: 2vh 2vw;
}

.dp4-h {
    font-size: 3.5vw;
    text-align: start;
    font-weight: 700;
    height: 100%;
    display: flex;
    align-items: center;
    width: 100%;

}

.case-study {
    display: flex;
    flex-direction: row-reverse;
}

.gpl4-p2 {
    text-align: end;
    font-size: 2vw;
}

.gp4-white {
    padding: 5vh 0;
}

/* Media Query */
@media only screen and (max-width: 479.99px) {
    .glh-4 {
        font-size: 6vw;
        font-weight: 500;
    }

    .glp-4 {
        font-size: 4vw;
        text-align: justify;
    }

    .gp-4 {
        padding: 3vh 3vw;
        border-radius: 5vw;
        display: flex;
        justify-content: center;
        background-size: cover;
        background-position: 25%;
        background-repeat: no-repeat;
    }

    .gl-col-2-card {
        height: 17vh;
        padding: 1vh 2vw;
        width: 40%;
        margin: 1.5vh 0;
        display: flex;
        justify-content: space-between;
    }

    .dp4-h {
        text-align: start;
        font-weight: 500;
        font-size: 6vw;
    }

    .gp4-white {
        padding: 0vh 5vw;
    }

    .col-2-para {
        font-size: 1.75vh;
        height: 100%;
        margin-top: 1vh;
    }

    .col-2-icon {
        height: 4vh;
        width: 4vh;
    }

    .gl-col-1 {
        padding-top: 1vh;
    }

    .gpl4-p2 {
        text-align: justify;
        font-size: 4vw;
    }

}

@media only screen and (min-width: 480px) and (max-width: 767.99px) {
    .glh-4 {
            font-size: 3vw;
        }
    
        .gp-4 {
            min-height: 30vh;
            border-radius: 5vw;
            padding: 2.5vh 4vw;
            display: flex;
            justify-content: center;
        }
    
        .gl-col-2-card {
            height: 15vh;
            justify-content: space-between;
            padding: 1vh 1.5vw;
        }
    
        .col-2-para {
            font-size: 2vw;
            margin-top: 1vh;
            height: 100%;
        }
    
        .dp4-h {
            text-align: start;
            font-size: 5vw;
        }
    
        .gp4-white {
            padding: 0vh 5vw;
        }
    
        .gpl4-p2 {
            font-size: 3vw;
        }
    
        .gl-col-1 {
            padding-top: 1vh;
        }
    
        .glp-4 {
            font-size: 2vw;
        }
    
        .glh-4 {
            font-size: 4dvi;
        }
    
        .col-2-icon {
            height: 4vh;
            width: 4vh;
        }
    
        .gpl4-p2 {
            text-align: start;
        }

}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .glh-4 {
        font-size: 3vw;
    }

    .gp-4 {
        min-height: 30vh;
        border-radius: 5vw;
        padding: 2.5vh 4vw;
        display: flex;
        justify-content: center;
    }

    .gl-col-2-card {
        height: 15vh;
        justify-content: space-between;
        padding: 1vh 1.5vw;
    }

    .col-2-para {
        font-size: 2vw;
        margin-top: 1vh;
        height: 100%;
    }

    .dp4-h {
        text-align: start;
        font-size: 5vw;
    }

    .gp4-white {
        padding: 0vh 5vw;
    }

    .gpl4-p2 {
        font-size: 3vw;
    }

    .gl-col-1 {
        padding-top: 1vh;
    }

    .glp-4 {
        font-size: 3vw;
    }

    .glh-4 {
        font-size: 4vh;
        font-weight: 500;
    }

    .col-2-icon {
        height: 4vh;
        width: 4vh;
    }

    .gpl4-p2 {
        text-align: start;
    }

}