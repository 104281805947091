.first-page {
    padding: 3vw;
    height: 90vh;
    display: flex;
    align-items: center;
}

.text-container {
    color: white;
    font-size: 3vw;
    font-weight: 700;
    margin-bottom: 5vh;
}

.text-container-normal {
    font-weight: 400;
    color: white;
    font-size: 1.5vw;
    margin-bottom: 5vh;
}

.text-cyan {
    color: #00DDB5;
    line-height: 5vw;
    word-wrap: break-word
}

.butn-box {
    margin-top: 10vh;
    background-image: linear-gradient(to bottom, #00DDB5, #00c0dd);
    height: 6vh;
    width: 40%;
    border-radius: 3vh;
    transition: all ease-in-out 0.5s;
}

.learn-more-butn {
    transition: all ease-in-out 0.5s;
    border: 0;
    color: white;
    height: 7vh;
    width: 100%;
    border-radius: 3vh;
    background-color: #1B1A1A;
    border: 0.25vh solid #00DDB5;
    transition: all ease 0.5s;
    display: flex;
    padding: 0.5vh;
    justify-content: space-between;
    align-items: center;
}

.butn-text {
    font-size: 1.5vw;
    width: 100%;
    font-weight: 500;
}

.butn-arrow,
.butn-arrow1 {
    height: 3vw;
    padding: 0.5vh;
    border-radius: 50%;
    filter: invert(1);
}

.butn-arrow1 {
    visibility: hidden;
}

.play-image {
    height: 70vh;
}

.learn-more-butn {
    &:hover {
        padding: 0vh;
        background-color: transparent;
        background-image: linear-gradient(to bottom, #00DDB5, #00c0dd);

        .butn-text {
            filter: invert(1);
            transition: all ease-in-out 0.5s;
        }

        .butn-arrow {
            transition: all ease-in-out 0.5s;
            filter: invert(0);
            margin-right: 0.5vh;
        }
    }
}

.image-container {
    position: relative;
    display: flex;
    justify-content: center;
}

.play-icon {
    position: absolute;
    height: 20vh;
    top: 37%;
    animation-name: zoom-in-out;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;

    &:hover {
        cursor: pointer;
        animation: none;
    }

    &:active {
        cursor: none;
        scale: 0.8;
    }
}

@keyframes zoom-in-out {
    0% {
        scale: 1;
    }

    50% {
        scale: 0.8;
    }

    100% {
        scale: 1;
    }
}

@media only screen and (max-width: 479.99px) {
    .text-container {
        font-size: 5vw;
        margin-bottom: 2vh;
    }

    .text-container-normal {
        font-weight: 100;
        font-size: 4vw;
        margin-bottom: 2vh;
    }

    .learn-more-butn {
        height: 5vh;
    }

    .butn-box {
        margin-top: 5vh;
        height: 5vh;
        width: 60%;
        border-radius: 3vh;
        transition: all ease-in-out 0.5s;
    }

    .butn-arrow,
    .butn-arrow1 {
        height: 7vw;
    }

    .butn-text {
        font-size: 4vw;
        font-weight: 400;
    }

    .butn-arrow1 {
        display: none;
    }

    .image-container {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .play-image {
        height: 45vh;
    }

    .play-icon {
        position: absolute;
        height: 20vh;
        top: 30%;
    }

}

@media only screen and (min-width: 480px) and (max-width: 767.99px) {
    .play-image {
        height: 40vh;
    }

    .image-container {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .play-icon {
        position: absolute;
        height: 20vh;
        top: 25%;
    }

}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .text-container {
        font-size: 5vw;
        margin-bottom: 2vh;
    }

    .text-container-normal {
        font-weight: 300;
        font-size: 3vw;
        margin-bottom: 2vh;
    }

    .butn-box {
        margin-top: 5vh;
        height: 6vh;
        width: 60%;
        border-radius: 3vh;
        transition: all ease-in-out 0.5s;
    }

    .butn-arrow,
    .butn-arrow1 {
        height: 5vw;
    }

    .image-container {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .play-image {
        height: 40vh;
    }

    .play-icon {
        position: absolute;
        height: 20vh;
        top: 25%;
    }

    .butn-text {
        font-size: 3vw;
        font-weight: 400;
    }
}