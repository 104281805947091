.App{
    background: #1B1A1A;
}
html,body{
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    background: #1B1A1A;
    width: 100%;
}

*::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
	border-radius: 0.25vw;
}

*::-webkit-scrollbar {
    width: 0.5vw;
    background-color: #F5F5F5;
        border-radius: 0.25vw;
}
*::-webkit-scrollbar-thumb {
    background-color: gray;border-radius: 0.25vw;
}