.GLIMPage6 {
    padding: 3vh 3vw;
}

.gp6-h-box {
    width: 100%;
    display: flex;
    justify-content: center;
}

.gp6-h {
    display: flex;
    flex-direction: column;
    width: fit-content;
    font-size: 3vw;
    font-weight: 700;
}

.gp6-underline-box {
    display: flex;
}

.gp6-underline {
    height: 0.5vh;
    width: 50%;
    display: flex;
}

.gp6-card-box {
    padding: 5vh 5vw;
    margin-bottom: 5vh;
}

.gp6-img {
    width: 70%;
}

.gp6-card-box-inner-card-number {
    font-size: 6vw;
    font-weight: 900;
    color: gray;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
}

.gp6-card-box-inner-card-heading {
    font-size: 4vw;
    font-weight: 500;
}

.gp6-card-box-inner-card-para {
    font-size: 1.5vw;
    margin-left: 10%;
    text-align: justify;
    padding: 3vh 5vw;
}


.gp6-card-content {
    display: flex;
}

.gp6-round {
    scale: 0.75;
}

.gp6-main {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.col-70 {
    width: 60%;
}

.col-50 {
    width: 40%;
    padding-left: 5vw;
}

.change-position {
    display: flex;
    flex-direction: row-reverse;
}
.count-image{
    height: 20vh;
}


/* Media Query */
@media only screen and (max-width: 479.99px) {
    .gp6-img {
        width: 60%;
        position: absolute;
        right: 20%;
    }

    .gp6-card-box-inner-card-para {
        font-size: 4vw;
        margin-left: 0;
        padding: 1vh 3vw;

    }

    .col-70 {
        width: 100%;
    }

    .col-50 {
        width: 100%;
        padding: 0;
        position: relative;
    }

    .gp6-round {
        scale: 0.5;
    }

    .gp6-h {
        font-size: 7vw;
        font-weight: 500;
    }

    .GLIMPage6 {
        padding: 0vh 0vw;
        margin-bottom: 3vh;
    }
    .gp6-card-box-inner-card-number{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        scale: 1.25;
        margin-left: 10%;
    }
    .gp6-card-box-inner-card-heading{
        margin: 0;
        font-size: 6vw;
    }
        .count-image {
            height: 100%;
        }
}

@media only screen and (min-width: 480px) and (max-width: 767.99px) {
    .gp6-img {
        width: 90%;
    }

    .gp6-card-box-inner-card-para {
        font-size: 2.25vw;
        margin-left: 0;
    }

    .gp6-h {
        font-size: 5vw;
    }

    .GLIMPage6 {
        padding: 0vh 0vw;
    }
.count-image {
    height: 10vh;
}
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {

    .gp6-img {
        width: 100%;
    }

    .gp6-card-box-inner-card-para {
        font-size: 2.5vw;
        margin-left: 0;
    }

    .gp6-h {
        font-size: 5vw;
    }

    .GLIMPage6 {
        padding: 0vh 0vw;
    }
        .col-70 {
            width: 60%;
        }
    
        .col-50 {
            width: 40%;padding-left: 0vw;
        }
        .gp6-card-box{
            margin-bottom: 0;
        }
                .count-image {
                    height: 10vh;
                }

}