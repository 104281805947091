.gp2{
    padding: 5vh 5vw 0 5vw;
}
.gp2-ques{
    font-size: 2.5vw;
    text-align: end;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.bold-500{
    font-weight: 500
}
.gp2-ans{
    font-size: 1.5vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
}
.gp2-empty{
    display: flex;
    justify-content: center;
    align-items: center;
}
.gp2-empty-span{
    background-color: #ffba00;
    height: 100%;
    width: 0.25vw;
}
.gp2-glim{
    font-size: 3vw;
}
.text-glim-2{
    font-size: 4vw ;
    font-weight: 500;
}
.change-namikaze {
    color: #20c997;
}
.show-small{
    display: none;
}
/* Media Query */
@media only screen and (max-width: 479.99px) {
    .lg-remove {
            display: none;
        }
    
        .gp2-ques {
            text-align: start;
            font-size: 6vw;
            display: inline;
        }
    
        .gp2-ans {
            font-size: 4vw
        }
    
        .text-glim-2 {
            font-size: 6vw;
        }
        .change-namikaze{
            color: #ffba00;
            font-weight: 500;
        }
                .show-small {
                    display: block;
                }
                .gp2{
                    padding: 2vh 5vw;
                }

}

@media only screen and (min-width: 480px) and (max-width: 767.99px) {
    .lg-remove {
            display: none;
        }
    
        .gp2-ques {
            text-align: start;
            font-size: 4vw;
            display: inline;
        }
    
        .gp2-ans {
            font-size: 2vw
        }
    
        .text-glim-2 {
            font-size: 5vw;
        }.show-small {
            display: block;
        }
    

}

@media only screen and (min-width: 768px) and (max-width: 1023px) {

    .lg-remove{
        display: none;
    }
    .gp2-ques{
        text-align: start;
        font-size: 4vw;
        display: inline;
    }
    .gp2-ans{
        font-size: 3vw
    }
    .text-glim-2 {
        font-size: 5vw;
    }.show-small {
        display: block;
    }

}