.AgilePage{
    background-color: white;
    padding: 5vh 5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.hex-image{
    height: 100vh;
}
.hex-img-container{
    display: flex;
    justify-content: center;
}